import { AspectRatio, Skeleton, HTMLChakraProps, Flex, SkeletonText } from '@chakra-ui/react';
import { FC } from 'react';

export const LectureCardSkeleton: FC<HTMLChakraProps<'div'>> = (props) => {
  return (
    <Flex flexDir="column" {...props}>
      <AspectRatio maxW="full" ratio={1.69}>
        <Skeleton />
      </AspectRatio>

      <Flex pos="relative" flexDir="column" flexGrow={1} p="4" backgroundColor="white">
        <Skeleton w="33%" h="20px" mb="2" />

        <SkeletonText h="full" noOfLines={3} skeletonHeight="4" spacing="2" py="1" mb="auto" />

        <Skeleton flexGrow={1} w="full" h="full" />
      </Flex>
    </Flex>
  );
};
