import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { useResource } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LockIconOpen from '@/assets/icons/ic-lock-open.svg';
import LockIcon from '@/assets/icons/ic-lock.svg';

import { LectureIncludes } from '@/components/ui/shared/lecture/FlippingLectureCard/components/LectureIncludes';
import { LectureKeywords } from '@/components/ui/shared/lecture/FlippingLectureCard/components/LectureKeywords';
import { queryThinEvent } from '@/queries/thin-events';
import { ThinLecture } from '@/resources/ThinLecture';

export const FlippedLectureCard: FC<{ lecture: ThinLecture }> = ({ lecture }) => {
  const { t } = useTranslation();
  const { data: event } = useResource(queryThinEvent(lecture.eventId.toString()));

  return (
    <Flex
      borderWidth="2px"
      borderColor="gray.100"
      backgroundColor="white"
      borderRadius="8"
      p="4"
      color="black"
      h="full"
      w="full"
      justifyContent="space-between"
      flexDir="column"
    >
      <Flex flexDir="column">
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight="bold" noOfLines={1}>
            {event?.name}
          </Text>
          <Icon
            as={lecture?.lectureAccessPermission ? LockIcon : LockIconOpen}
            h={6}
            w={6}
            color={lecture?.lectureAccessPermission ? 'gray.500' : 'teal.500'}
          />
        </Flex>
        <Heading noOfLines={3} mb="3" fontSize="md" fontWeight="bold" mt={2}>
          {lecture.title}
        </Heading>
        <LectureKeywords data={lecture} />
        <Text mt={2} noOfLines={3}>
          {lecture.abstract}
        </Text>
      </Flex>
      <Flex mt={5} justifyContent="space-between" alignItems="center">
        <LectureIncludes lecture={lecture} />
        <NextLink key={lecture.id} href={`/lecture/${lecture.linkSlug}`} passHref>
          <Button onClick={(e) => e.stopPropagation()} h={10} px={5} py="2">
            {t('library.lecture.go_to_button')}
          </Button>
        </NextLink>
      </Flex>
    </Flex>
  );
};
