/* eslint-disable camelcase */
import { _QueryResources } from '@/lib/datx-jsonapi-react';
import { Keywords } from '@/resources/Keywords';

export const queryKeywordsByLectureId = (lectureId: string) => {
  return lectureId
    ? ([
        Keywords,
        {
          filter: {
            lecture_id: lectureId,
          },
        },
      ] as _QueryResources<Keywords>)
    : null;
};
