import { EventSort } from '@/enums/EventSort';

export const eventSortingOptions = [
  {
    label: 'alphabeticallyAZ',
    value: `${EventSort.Name},${EventSort.StartsAt}`,
  },
  {
    label: 'alphabeticallyZA',
    value: `-${EventSort.Name},${EventSort.StartsAt}`,
  },
  {
    label: 'dateAsc',
    value: `-${EventSort.StartsAt},${EventSort.Name}`,
  },
  {
    label: 'dateDesc',
    value: `${EventSort.StartsAt},${EventSort.Name}`,
  },
];
