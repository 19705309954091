import { Skeleton, HStack, Text, Flex } from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import { FC } from 'react';

import { queryKeywordsByLectureId } from '@/fetchers/keywords';
import { ThinLecture } from '@/resources/ThinLecture';

interface ILectureKeywordsProps {
  data: ThinLecture;
}

const LectureKeywordsFallback: FC = () => {
  return (
    <HStack>
      <Skeleton borderRadius={24} height={6} />
      <Skeleton borderRadius={24} height={6} />
    </HStack>
  );
};

export const LectureKeywords: FC<ILectureKeywordsProps> = ({ data: lecture }) => {
  const { data: keywords, error } = useResources(queryKeywordsByLectureId(lecture.id));

  if (error) {
    throw error;
  }

  if (!keywords) {
    return <LectureKeywordsFallback />;
  }

  if (keywords.length === 0) {
    return null;
  }

  return (
    <Flex gap={2} overflowY="hidden" h="38px" alignItems="baseline" flexWrap="wrap">
      {keywords.map(({ name }) => (
        <Text noOfLines={1} borderColor="black" borderWidth="1px" px="2" py="1" borderRadius="100px" key={name}>
          {name}
        </Text>
      ))}
    </Flex>
  );
};
