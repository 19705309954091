import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';

import { eventSortingOptions } from '@/constants/event-sorting-options';
import { EventListStatus } from '@/enums/EventListStatus';
import { EventSort } from '@/enums/EventSort';
import {
  QueryResourceRelationship,
  QueryResources,
  QueryResourcesInfinite,
  QueryResource,
} from '@/lib/datx-jsonapi-react';
import { EventConfiguration } from '@/resources/EventConfiguration';
import { ThinEvent } from '@/resources/ThinEvent';

interface IQueryEventsByQueryInfiniteProps {
  filters: Record<string, string | Array<string>>;
  sort?: string;
  pageSize: number;
}
export const DEFAULT_EVENTS_INFINITE_SORT = `-${EventSort.StartsAt},${EventSort.Name}`;

export const queryThinEvent = (id: string): QueryResource<ThinEvent> => {
  return id ? [ThinEvent, id] : null;
};

export const queryThinEventConfigurationRelationship = (
  eventId: string
): QueryResourceRelationship<EventConfiguration> => () => (eventId ? [ThinEvent.type, eventId, 'configuration'] : null);

export const queryEventsByQueryInfinite = ({
  filters,
  sort = DEFAULT_EVENTS_INFINITE_SORT,
  pageSize = 16,
}: IQueryEventsByQueryInfiniteProps): QueryResourcesInfinite<ThinEvent> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }
    const pageNumber = pageIndex + 1;

    return [
      ThinEvent.type,
      {
        filter: {
          ...omitBy(filters, isNil),
        },
        sort,
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};

export const queryLectureEvents = (eventIds: Array<number>): QueryResources<ThinEvent> => () =>
  eventIds
    ? [
        ThinEvent,
        {
          filter: {
            id: uniq(eventIds),
          },
        },
      ]
    : null;

export const queryFeaturedThinEvent = (): QueryResources<ThinEvent> => {
  return [
    ThinEvent,
    {
      filter: {
        featured: 'true',
      },
    },
  ];
};

export const queryThinEventsList = (
  filters: Record<string, string | Array<string>>,
  sort,
  pageSize = 10
): QueryResourcesInfinite<ThinEvent> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }
    const pageNumber = pageIndex + 1;

    if (!filters.scope) {
      filters.scope = EventListStatus.live;
    }

    const defaultSort =
      !filters.query && filters.scope === EventListStatus.upcoming ? eventSortingOptions[3] : eventSortingOptions[2];

    return [
      ThinEvent,
      {
        filter: {
          ...omitBy(filters, isNil),
        },
        sort: sort || defaultSort.value,
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};
