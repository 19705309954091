import { IJsonapiModel, Response } from 'datx-jsonapi';
import { flatten, last } from 'lodash';

import { ICollectionMeta } from '@/interfaces/ICollectionMeta';

export function extractSWRInfiniteData<T extends IJsonapiModel, Data extends Response<T> = Response<T>>(
  data: Array<Data>,
  error: any,
  size: number
) {
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && !data[size - 1]);

  const isEmpty = data?.length === 0 || (data?.[0]?.data as Array<T>)?.length === 0;

  const isReachingEnd = isEmpty || !data?.[data.length - 1]?.links?.next;

  const items = flatten(
    data?.map((page) => {
      return page.data;
    })
  );

  const meta = last(data)?.meta as ICollectionMeta;

  return {
    isLoadingMore,
    isReachingEnd,
    isEmpty,
    items,
    meta,
  };
}
