import { Box, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { ThinLecture } from '@/resources/ThinLecture';

export const LectureIncludes: FC<{ lecture: ThinLecture }> = ({ lecture }) => {
  const includesList = useMemo(() => {
    const lectureIncludesMapper = {
      video: lecture.playlist,
      paper: lecture.paper,
      slides: lecture.slideshow,
      poster: lecture.posterDocument,
    };

    return Object.entries(lectureIncludesMapper)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(', ');
  }, [lecture]);

  if (includesList === '') {
    return <Box />;
  }

  return (
    <Box fontSize="sm">
      <Text fontWeight="bold" textTransform="uppercase">
        LECTURE INCLUDES
      </Text>
      <Text>{includesList}</Text>
    </Box>
  );
};
