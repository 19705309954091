export enum LectureSort {
  TagPosition = 'tag_position',
  // Title should be 'title' but it is translated on BE "title_i18n"
  // so they need to implement sort for that field
  Title = 'title',
  SpeakerLastName = 'speaker_last_name',
  Name = 'name',
  HeldAt = 'held_at',
  ViewCount = 'video_started_count',
  Relevance = 'relevance',
}
