import { Response } from 'datx-jsonapi';
import uniq from 'lodash/uniq';

import { QueryResource } from '@/lib/datx-jsonapi-react';
import { LectureAccessPermissions } from '@/resources/LectureAccessPermissions';
import { OmnisearchThinLecture } from '@/resources/OmnisearchThinLecture';
import { ThinLecture } from '@/resources/ThinLecture';
import { AppCollection } from '@/stores/AppStore';

export function queryLectureAccessPermission(lectureId: string): QueryResource<LectureAccessPermissions> {
  return lectureId ? [LectureAccessPermissions, lectureId] : null;
}

export const fetchLectureAccessPermissionsSideload = (store: AppCollection) => async (
  response: Response<ThinLecture>
) => {
  const lectureIds = (response.data as Array<ThinLecture>)?.map((lecture) => lecture.id);

  const result = await store.fetchAll(LectureAccessPermissions, {
    filter: {
      lecture_id: uniq(lectureIds),
    },
  });

  return result;
};

export const fetchOmnisearchLectureAccessPermissionsSideload = (store: AppCollection) => async (
  response: Response<OmnisearchThinLecture>
) => {
  const lectureIds = (response.data as Array<OmnisearchThinLecture>)?.map((lecture) => lecture.id);

  const result = await store.fetchAll(LectureAccessPermissions, {
    filter: {
      lecture_id: uniq(lectureIds),
    },
  });

  return result;
};
