import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ThinProfile } from '@/resources/ThinProfile';

export const LectureAuthorsText: FC<{ profiles: Array<ThinProfile> }> = ({ profiles }) => {
  const { t } = useTranslation();

  if (!profiles?.length) return <Text />;
  const hasMoreThanOneAuthor = profiles.length > 1;
  const othersText = hasMoreThanOneAuthor ? t('library.lecture.authors', { count: profiles.length - 1 }) : '';

  return <Text noOfLines={1}>{`${profiles[0].fullName} ${othersText}`}</Text>;
};
