import { _QueryResources, QueryResources, QueryResourcesInfinite, QueryResource } from 'datx-jsonapi-react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { LectureSort } from '@/enums/LectureSort';
import { ThinLecture } from '@/resources/ThinLecture';

export const queryLectures = (sessionId: string): _QueryResources<ThinLecture> => {
  return sessionId
    ? [
        ThinLecture,
        {
          filter: {
            event_session_id: sessionId,
            scope: 'all',
          },
          sort: 'held_at',
          params: [
            {
              key: 'page[size]',
              value: '100',
            },
          ],
        },
      ]
    : null;
};

interface IQueryLecturesByQueryInfiniteProps {
  filters: Record<string, string>;
  sort?: string;
  pageSize: number;
}

export const DEFAULT_LECTURE_INFINITE_SORT = `-${LectureSort.HeldAt}`;

export const queryLecturesByQueryInfinite = ({
  filters,
  sort = DEFAULT_LECTURE_INFINITE_SORT,
  pageSize = 16,
}: IQueryLecturesByQueryInfiniteProps): QueryResourcesInfinite<ThinLecture> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }
    const pageNumber = pageIndex + 1;

    return [
      ThinLecture.type,
      {
        filter: {
          scope: 'all',
          ...omitBy(filters, isNil),
        },
        sort: [sort, 'id'],
        include: ['sorted_profiles', 'event_session', 'tag', 'event'],
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};

export const queryThinLectureWithTag = (lectureId: string): QueryResource<ThinLecture> => () =>
  lectureId && [
    ThinLecture,
    lectureId,
    {
      include: ['sorted_profiles', 'tag'],
      filter: { scope: 'all' },
    },
  ];

export const queryOriginalsLectures = (subjectId: string): QueryResources<ThinLecture> => {
  if (!subjectId) return null;
  return [
    ThinLecture,
    {
      filter: {
        scope: 'published',
        library: 'true',
        subject_subtree_of_id: subjectId,
      },
      include: ['event_session', 'sorted_profiles', 'tag'],
      sort: '-held_at',
      params: [
        { key: 'page[number]', value: '1' },
        {
          key: 'page[size]',
          value: '4',
        },
      ],
    },
  ];
};
